<template>
    <div class="h-screen w-64  fixed md:relative bg-white border border-gray-200 text-gray-900">
        <!-- profile -->
        <div class="h-screen w-full bg-white relative">
            <div class="relative w-full h-20 flex items-center">
                <div v-if="is_krakatau" class="px-2 py-4">
                    <img :src="detail ? default_url + detail.foto : '/img/Picture1.png'" class="h-10 object-contain" />
                </div>
                <div v-if="is_krakatau" class="px-2">
                    <img :src="detail ? default_url + detail.foto : '/img/krakatau.svg'" class="h-10 object-contain" />
                </div>
                <div v-if="is_krakatau" class="px-3">
                    <img :src="detail ? default_url + detail.foto : '/img/logo_nada.svg'" class="h-10 object-contain" />
                </div>
                <div v-else class="px-4 py-8">
                    <img :src="detail ? default_url + detail.foto : '/img/logonada.svg'" class="h-12 object-contain" />
                </div>
                <div class="absolute md:hidden right-2 top-2 cursor-pointer" @click="setSidebar(!sidebar)">
                    <unicon name="times" class="cursor-pointer" fill="gray"></unicon>
                </div>
            </div>
            <div style="height:80%" class="flex flex-wrap w-full overflow-auto bg-white">
                <ul class="w-full">
                    <div v-for="(item, index) in menu" :key="item.url" class="mt-3">
                        <div class="text-gray-300 font-semibold text-xs py-4 px-4 font-menu" v-if="index == 1">
                            NAVIGATION</div>
                        <div class="text-gray-300 font-semibold text-xs py-4 px-4 font-menu" v-if="index == 0">DASHBOARD
                        </div>
                        <div v-if="!item.child.length" v-on:mouseover="eventHover(item.url)" @click="goTo(item.url)"
                            v-on:mouseleave="eventHoverClose(item.url)"
                            :class="`${urlFirt() == item.url || item.hover ? 'text-blue-500  ' : 'text-gray-300 '} font-medium cursor-pointer`">
                            <div v-on:mouseover="eventHover(item.url)" v-on:mouseleave="eventHoverClose(item.url)"
                                :class="`${urlFirt() == item.url || item.hover ? 'w-11/12 rounded-r-lg bg-blue-100 border-l-4 border-blue-500 px-4' : 'px-5'}  py-3 flex flex-wrap items-center content-center`">

                                <unicon width="20px" height="20px" v-on:mouseover="eventHover(item.url)"
                                    v-on:mouseleave="eventHoverClose(item.url)" :name="item.icon" class="mr-2"
                                    :fill="urlFirt() == item.url || item.hover ? '#3B82F6' : '#A1A1AA'"></unicon>
                                <!-- <img src="/img/uil_dashboard.svg" alt=""> -->
                                <!-- <i class="text-lg mr-3"  v-on:mouseover="eventHover(item.url)" v-on:mouseleave="eventHoverClose(item.url)"  :class="item.icon"></i> -->

                                <span v-on:mouseover="eventHover(item.url)" v-on:mouseleave="eventHoverClose(item.url)"
                                    :class="`leading-3 text-sm ${urlFirt() == item.url || item.hover ? '' : 'font-menu'}`">{{ item.name }}</span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-on:mouseover="eventHover(item.url)" v-on:mouseleave="eventHoverClose(item.url)"
                                :class="`${item.statusChild || item.hover ? 'text-blue-500  ' : 'text-gray-300 '} font-medium cursor-pointer`"
                                @click="openChild(item.url)">
                                <div v-on:mouseover="eventHover(item.url)" v-on:mouseleave="eventHoverClose(item.url)"
                                    :class="`${item.statusChild || item.hover ? 'w-11/12 rounded-r-lg bg-blue-100 border-l-4 border-blue-500 pl-4' : 'px-5'}  py-3 flex flex-wrap justify-between items-center content-center`">
                                    <div class="flex items-center">
                                        <unicon width="20px" height="20px" v-on:mouseover="eventHover(item.url)"
                                            v-on:mouseleave="eventHoverClose(item.url)" :name="item.icon" class="mr-2"
                                            :fill="item.statusChild || item.hover ? '#3B82F6' : '#A1A1AA'"></unicon>

                                        <span v-on:mouseover="eventHover(item.url)"
                                            v-on:mouseleave="eventHoverClose(item.url)"
                                            :class="`leading-3 text-sm ${item.statusChild || item.hover ? '' : 'font-menu'}`">{{ item.name }}</span>
                                    </div>

                                    <div class="flex items-center">
                                        <unicon name="angle-down" unicon width="20px" height="20px"
                                            v-on:mouseover="eventHover(item.url)"
                                            v-on:mouseleave="eventHoverClose(item.url)" class="mr-2"
                                            :fill="item.statusChild || item.hover ? '#3B82F6' : '#A1A1AA'"></unicon>

                                    </div>

                                </div>
                            </div>
                            <div class="border-l-4 border-blue-500" v-if="item.statusChild">
                                <div @click="goTo(child.url)" v-for="child in item.child" :key="child.url"
                                    :class="`${urlFirt() == child.url || child.hover ? 'text-blue-500  ' : 'text-gray-300 '} font-medium cursor-pointer`">
                                    <div class="pl-11  w-11/12 rounded-r-lg">
                                        <div class="text-sm hover:text-blue-500  py-3">{{ child.name }}</div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </ul>
            </div>

        </div>


    </div>
</template>
<style scoped>
.menu {
    cursor: pointer;
}

.font-menu {
    color: #9B9B9B !important;
}

.aktif {
    background: linear-gradient(60deg, #00d7f3, #00bcd4);
    background-image: linear-gradient(60deg, rgb(0, 200, 226), rgba(0, 188, 212));
    color: white;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
    },
    data() {
        return {
            default_url: process.env.VUE_APP_IMAGES,
            img: 'public/default.png',
            statushover: false,
            menu: [],
            is_krakatau: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            status: 'auth/status',
            menu_state: 'auth/menu',
            detail: 'auth/detail',
            sidebar: 'sidebar/sidebar',
        }),
        isKrakatauUser() {
            console.log("is karkatau", this.user)
            return this.user && this.user.name === "Krakatau Smart" && this.user.role_id === 35;
        },
        aktivClass: function () {
            if (this.detail) {
                return 'color:white;background:' + (this.detail.color ? this.detail.color : 'linear-gradient(60deg, #00d7f3, #00bcd4)')
            } else {
                return `background: linear-gradient(60deg, #00d7f3, #00bcd4) !important;
            background-image: linear-gradient(60deg, rgb(0, 200, 226), rgba(0, 188, 212)) !important;
            color: white !important;`
            }
        }
    },
    methods: {
        ...mapActions({
            setSidebar: 'sidebar/setSidebar'
        }),
        goTo(url) {
            this.$router.push(url)
        },
        openChild(url) {
            let item = this.menu.find(x => x.url === url)
            let index = this.menu.findIndex(x => x.url === url)
            item.statusChild = !item.statusChild
            item.hover = false
            this.menu.splice(index, 1, item)
        },
        urlFirt() {
            let str = this.$route.path
            str = str.split('/')
            return '/' + str[1]
        },
        async eventHover(url) {
            let findItem = this.menu.find(x => x.url === url)
            let index = this.menu.findIndex(x => x.url === url)
            findItem.hover = true
            this.menu.splice(index, 1, findItem)
        },

        async eventHoverClose(url) {
            let findItem = this.menu.find(x => x.url === url)

            let index = this.menu.findIndex(x => x.url === url)
            findItem.hover = false
            this.menu.splice(index, 1, findItem)

        },

        statusAccess(status) {
            return status.includes(this.status)
        },
        roleAccess(status) {
            return status.includes(this.user.role_id)

        },

    },
    created() {
        this.menu = [...this.menu_state];
        this.is_krakatau = this.isKrakatauUser;
        console.log("..........",this.menu, "............")
        console.log(this.default_url)
    }
}
</script>